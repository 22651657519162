import { EVENT_LISTENERS, dispatchCustomEvent } from "./event_listeners"

export const headers = { Accept: "application/json" }

const handleRequest = async (requestMethod, url, body) => {
  try {
    const response = await requestMethod(url, {
      body,
      headers: { Accept: "application/json" },
    })
    let json = null
    try { json = await response.json } catch {}

    if (response.ok) {
      return json
    } else {
      throw json || "Error failed with no response"
    }
  } catch (error) {
    console.error("Error processing request:", error)
    dispatchCustomEvent(EVENT_LISTENERS.SNACKBAR, { text: error })
    throw error
  }
}

export default handleRequest
