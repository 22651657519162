import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input/intlTelInputWithUtils"

export default class extends Controller {
  static values = {
    fieldName: String
  }

  connect() {
    const fieldName = this.fieldNameValue
    const input = document.querySelector("#iti")
    intlTelInput(input, {
      initialCountry: "us",
      separateDialCode: true,
      strictMode: true,
      hiddenInput: (_) => { return {phone: fieldName, country: "country_code"} },
    })
  }
}
