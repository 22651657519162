import { Controller } from "@hotwired/stimulus"

export const SNACKBAR_TYPES = {
  DANGER: "danger",
  SUCCESS: "success",
}

const SNACKBAR_TYPE_CLASSES = {
  [SNACKBAR_TYPES.DANGER]: "snackbar--danger",
  [SNACKBAR_TYPES.SUCCESS]: "snackbar--success",
}

export default class extends Controller {
  static targets = ["text"]
  static values = {
    delay: {
      type: Number,
      default: 5000,
    },
  }

  initialize() {
    this.hide = this.hide.bind(this)
  }

  show(event) {
    this.textTarget.innerText =
      event?.detail?.text || stimulus.snackbar.default_message
    Object.values(SNACKBAR_TYPE_CLASSES).forEach((className) => {
      this.element.classList.remove(className)
    })
    this.element.classList.add(
      SNACKBAR_TYPE_CLASSES[event?.detail?.type || SNACKBAR_TYPES.DANGER]
    )
    this.element.classList.remove("snackbar-hide")
    this.element.classList.add("snackbar-show")
    this.timeout = setTimeout(this.hide, this.delayValue)
  }

  hide() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.element.classList.remove("snackbar-show")
    this.element.classList.add("snackbar-hide")
  }
}
